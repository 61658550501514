import React from 'react'

import Layout from '../components/layout'
import Checkout from '../components/checkout'

const IndexPage = () => (
  <Layout>
    <Checkout />
  </Layout>
)

export default IndexPage
