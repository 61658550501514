import { createMuiTheme } from '@material-ui/core/styles'
const inputBoxTheme = createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: '2px solid #F26768',
        },
        '&:after': {
          borderBottom: '2px solid #F26768',
        },
      },
    },
  },
})
export default inputBoxTheme
