import React from 'react'
import './checkout.css'
import Hero from '../components/hero'
import {
  Card,
  CardContent,
  CardMedia,
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
} from '@material-ui/core'

import { MuiThemeProvider } from '@material-ui/core/styles'
import inputTheme from './inputTheme'

// Below is where the checkout component is defined.
// It has several functions and some default state variables.
const Checkout = class extends React.Component {
  state = {
    disabled: false,
    buttonText: 'DONATE NOW',
    paymentMessage: '',
    amount: 0,
  }

  updateAmount(event) {
    this.setState({ amount: Number.parseFloat(event.target.value, 10) })
  }

  resetButton() {
    this.setState({ disabled: false, buttonText: 'DONATE NOW', amount: 0 })
  }

  componentDidMount() {
    this.stripeHandler = window.StripeCheckout.configure({
      // You’ll need to add your own Stripe public key to the `checkout.js` file.
      key: 'pk_live_OJYJprSiMh07CwoANDpwHEDb',
      closed: () => {
        this.resetButton()
      },
    })
  }

  openStripeCheckout(event) {
    const amount = this.state.amount * 100

    event.preventDefault()
    this.setState({ disabled: true, buttonText: 'WAITING...' })
    this.stripeHandler.open({
      name: `PK Vacay Scholarship Fund`,
      amount: amount,
      image: `https://s3.amazonaws.com/c3conference/wp-content/uploads/favicon1.png`,
      token: token => {
        fetch(
          `https://b220wvxjhg.execute-api.us-east-1.amazonaws.com/dev/checkout`,
          {
            method: 'POST',
            mode: 'no-cors',
            body: JSON.stringify({
              token,
              amount,
            }),
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
          }
        )
          .then(res => {
            console.log('Transaction processed successfully')
            this.resetButton()
            this.setState({ paymentMessage: 'Donation Successful!' })
            return res
          })
          .catch(error => {
            console.error('Error:', error)
            this.setState({ paymentMessage: 'Donation Failed' })
          })
      },
    })
  }

  render() {
    return (
      <Card style={{ width: 400 }}>
        <CardMedia title="PK Vacay Scholarship Fund">
          <Hero />
        </CardMedia>
        <CardContent>
          <FormControl style={{ width: '100%' }}>
            <MuiThemeProvider theme={inputTheme}>
              <InputLabel focused={false}>Amount</InputLabel>
              <Input
                type="number"
                value={this.state.amount}
                onChange={this.updateAmount.bind(this)}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                style={{ margin: '1rem 0' }}
              />
            </MuiThemeProvider>
            <Button
              variant="contained"
              onClick={event => this.openStripeCheckout(event)}
              disabled={this.state.disabled}
              style={{
                backgroundColor: '#F26768',
                borderColor: '#F26768',
                color: '#fff',
              }}
            >
              {this.state.buttonText}
            </Button>
            <FormHelperText>{this.state.paymentMessage}</FormHelperText>
          </FormControl>
        </CardContent>
      </Card>
    )
  }
}

export default Checkout
